import React, { useEffect } from 'react';
import { Image, Typography, InfoTag } from '@airtel-web/legos';
import { SubscriptionCardPropTypes } from './types/SubscriptionCardPropTypes';
import { navigateTo } from '../../../../../../utils/utils';
import { fireAnalytics } from '../../../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './SubscriptionCard.scss';

const SubscriptionCard = (props: SubscriptionCardPropTypes) => {
   const { subscriptionData, index, customCampaignId } = props;

   const cardEvents = {
      section: ANALYTICS_CONSTANTS.subscriptionForYou,
      subSection: subscriptionData.mainTitle?.toLowerCase(),
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: subscriptionData.mainTitle?.toLowerCase(),
      eventValue: subscriptionData.price?.toLowerCase(),
      customValue: subscriptionData.discount?.toLowerCase(),
      customLabel: ANALYTICS_CONSTANTS.card,
      isInteractive: '0',
      offerSource: subscriptionData.offerSource,
      customCampaignId: customCampaignId,
      offerId: subscriptionData.offerId,
      horizontalPosition: index.toString(),
      verticalPosition: '7',
   };

   useEffect(() => {
      fireAnalytics(cardEvents, `subscriptions-card-${index}`);
   }, []);

   const onCardClick = () => {
      fireAnalytics({
         ...cardEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         isInteractive: '1',
      });
      navigateTo(subscriptionData.ctaLink);
   };

   return (
      <div
         id={`subscriptions-card-${index}`}
         className="subscriptions-card"
         onClick={onCardClick}
      >
         <Image
            draggable="false"
            src={subscriptionData.image}
            alt="subsciption image"
            className="subscription-card-image"
            height="92"
            width="156"
         />
         <div className="subscriptions-card-content">
            <Typography
               color={colors.black900}
               type="body-para-sm-bold"
               data-testid="subscriptionDataMainTitle"
               className="subscription-card-main-title"
            >
               {subscriptionData.mainTitle}
            </Typography>
            {subscriptionData.duration && (
               <Typography
                  type="body-single-line-xs"
                  color={colors.black700}
                  className="subscriptions-card-content-duration"
               >
                  {subscriptionData.duration}
               </Typography>
            )}
            <div className="subscriptions-card-content-price">
               <Typography
                  color={colors.black900}
                  type="body-single-line-md-bold"
                  data-testid="subscriptionPrice"
               >
                  ₹{subscriptionData.price}
               </Typography>
               {subscriptionData.cutPrice && (
                  <div className="subscriptions-card-content-cutprice-container">
                     <Typography
                        type="body-single-line-xs"
                        color={colors.black800}
                        className="subscriptions-card-content-cutprice"
                     >
                        ₹{subscriptionData.cutPrice}
                     </Typography>
                  </div>
               )}
               {subscriptionData.discount && (
                  <InfoTag
                     size="sm"
                     subType="offer"
                     type="inline"
                     className="subscriptions-card-content-discount"
                  >
                     {subscriptionData.discount}
                  </InfoTag>
               )}
            </div>
         </div>
      </div>
   );
};

export default SubscriptionCard;
