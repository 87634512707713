import React, { FC, useRef, useState } from 'react';
import {
   GridColumn,
   GridRow,
   IconButton,
   LeftArrowIcon,
   RightArrowIcon,
   Slider,
   SliderOnChangeTypes,
   SliderRefTypes,
   Typography,
   useGetMediaBreakpoint,
} from '@airtel-web/legos';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import {
   RECOMMENDATIONS_SECTION_TEST_ID,
   RECOMMENDATIONS_SLIDER_BREAKPOINTS,
} from './RecommendationsConstants';
import { RecommendationsTypes } from './types/RecommendationsPropTypes';
import RecommendationsSlide from './view/RecommendationsSlide';
import './Recommendations.scss';

const Recommendations: FC<RecommendationsTypes> = (props) => {
   const { sectionCmsData } = props;
   const { dataAttributes: sectionDataAttributes = {} } = sectionCmsData;
   const slides = sectionCmsData?.widgetContainers;
   const { heading } = sectionDataAttributes;
   const sliderRef = useRef<SliderRefTypes>(null);
   const [currSlideIndex, setCurrSlideIndex] = useState(0);
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isMdDevice = mediaBreakpoint === 'md';
   const isSmallDevice = mediaBreakpoint === 'xs';

   let dataList: any = [];
   if (slides?.length > 0) {
      dataList = slides.map((item, index) => {
         const { dataAttributes, uiAttributes } = item;
         return {
            children: (
               <RecommendationsSlide
                  customCampaignId={sectionDataAttributes.customCampaignId}
                  dataAttributes={dataAttributes}
                  uiAttributes={uiAttributes}
                  index={index}
               />
            ),
         };
      });
   }

   const handleBannerNext = () => {
      if (sliderRef.current && sliderRef.current.nextSlide) sliderRef.current.nextSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.recommendedForYou,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.rightArrow,
         eventValue: 'NA',
         customLabel: ANALYTICS_CONSTANTS.button,
         customValue: '',
         isInteractive: '1',
         horizontalPosition: '1',
         verticalPosition: '6',
      });
   };

   const handleBannerPrev = () => {
      if (sliderRef.current && sliderRef.current.prevSlide) sliderRef.current.prevSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.recommendedForYou,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.leftArrow,
         eventValue: 'NA',
         customLabel: ANALYTICS_CONSTANTS.button,
         customValue: '',
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '6',
      });
   };

   const handleSlideChange = (params: SliderOnChangeTypes) => {
      const { currIndex } = params;
      setCurrSlideIndex(currIndex);
   };

   const pageSlide = isMdDevice
      ? `${Math.ceil((currSlideIndex + 2) / 2)}/${Math.ceil(dataList.length / 2)}`
      : `${currSlideIndex + 1}/${dataList.length}`;

   let autoPlayDelay = 5000;
   if (sectionDataAttributes?.autoPlayDelay >= 5000) {
      autoPlayDelay = sectionDataAttributes.autoPlayDelay;
   }
   const autoPlay = sectionDataAttributes?.autoPlay
      ? sectionDataAttributes.autoPlay
      : false;

   const slideTransitionSpeed = sectionDataAttributes?.slideTransitionSpeed
      ? sectionDataAttributes.slideTransitionSpeed
      : 1000;

   const showSliderButtons = isMobile || isMdDevice || slides.length > 3;

   return (
      <div className="recommendations-section-container icon-button-black800">
         <GridRow
            justifyContent="center"
            direction="row-as-column"
            className="recommendations-section"
            noMargin={isMdDevice || isMobile}
         >
            <GridColumn
               noGutter
               className="recommendations-section-heading"
               alignSelf="center"
            >
               <Typography
                  type={isSmallDevice ? 'body-single-line-lg-bold' : 'h4-bold'}
                  color={colors.black900}
               >
                  {heading}
               </Typography>
            </GridColumn>

            <GridColumn noGutter={isMdDevice || isMobile}>
               {dataList.length > 0 && (
                  <Slider
                     className="slider"
                     data-testid={RECOMMENDATIONS_SECTION_TEST_ID.slider}
                     ref={sliderRef}
                     autoPlay={autoPlay}
                     autoPlayDelay={autoPlayDelay}
                     breakpoints={RECOMMENDATIONS_SLIDER_BREAKPOINTS}
                     data={dataList}
                     distanceToSwipeSlide={50}
                     swipeGesture={isMobile}
                     loop
                     slideTransitionSpeed={slideTransitionSpeed}
                     onSlideChangeHandler={handleSlideChange}
                  />
               )}
            </GridColumn>

            {showSliderButtons && (
               <GridColumn className="controls-column">
                  <GridRow className="slider-control-btn-section">
                     <IconButton
                        data-testid={RECOMMENDATIONS_SECTION_TEST_ID.prevSlideButton}
                        id="previousSlide"
                        className="prev-btn"
                        type="primary"
                        ariaLabel='Previous Slide'
                        isDisabled={currSlideIndex === 0}
                        size="xs"
                        icon={LeftArrowIcon}
                        onClick={handleBannerPrev}
                     />
                     <Typography
                        type="body-single-line-xs"
                        color={colors.black800}
                        data-testid={RECOMMENDATIONS_SECTION_TEST_ID.currentBannerDiv}
                     >
                        {pageSlide}
                     </Typography>
                     <IconButton
                        data-testid={RECOMMENDATIONS_SECTION_TEST_ID.nextSlideButton}
                        id="nextSlide"
                        className="next-btn"
                        type="primary"
                        ariaLabel='Next Slide'
                        size="xs"
                        icon={RightArrowIcon}
                        onClick={handleBannerNext}
                     />
                  </GridRow>
               </GridColumn>
            )}
         </GridRow>
      </div>
   );
};

export default Recommendations;
