import React, { FC, useRef, useState } from 'react';
import {
   IconButton,
   Typography,
   Slider,
   SliderRefTypes,
   GridRow,
   GridColumn,
   LeftArrowIcon,
   RightArrowIcon,
   useGetMediaBreakpoint,
} from '@airtel-web/legos';
import HeroBannerSlide from './view/hero-banner-slide/HeroBannerSlide';
import { HeroBannerPropTypes } from './types/HeroBannerPropTypes';
import { HERO_BANNER_TEST_ID } from './HeroBannerConstants';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './HeroBanner.scss';

const HeroBanner: FC<HeroBannerPropTypes> = (props) => {
   const { sliderData } = props;
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const [currentBanner, setCurrentBanner] = useState<number>(0);
   const sliderRef = useRef<SliderRefTypes>(null);

   const handleCurrentBannerChange = (value: number) => {
      setCurrentBanner(value);
   };

   const handleBannerNext = () => {
      if (sliderRef.current && sliderRef.current.nextSlide) sliderRef.current.nextSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.offerCard,
         subSection: ANALYTICS_CONSTANTS.primary,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.rightArrow,
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: currentBanner.toString(),
         verticalPosition: '2',
      });
   };

   const handleBannerPrev = () => {
      if (sliderRef.current && sliderRef.current.prevSlide) sliderRef.current.prevSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.offerCard,
         subSection: ANALYTICS_CONSTANTS.primary,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.leftArrow,
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: currentBanner.toString(),
         verticalPosition: '2',
      });
   };

   const dataList = sliderData.widgetContainers.map((widgetContainer, index) => {
      return {
         children: (
            <HeroBannerSlide
               attributes={widgetContainer.dataAttributes}
               index={index}
               currentBanner={currentBanner}
               customCampaignId={sliderData.dataAttributes.customCampaignId}
            />
         ),
      };
   });

   const autoPlayDelayTemp = sliderData?.dataAttributes?.autoPlayDelay;
   const autoPlayDelay =
      autoPlayDelayTemp && autoPlayDelayTemp >= 10000 ? autoPlayDelayTemp : 10000;

   const autoPlay = sliderData.dataAttributes.autoPlay
      ? sliderData.dataAttributes.autoPlay
      : false;

   const slideTransitionSpeed = sliderData.dataAttributes.slideTransitionSpeed
      ? sliderData.dataAttributes.slideTransitionSpeed
      : 1000;

   return (
      <div className="hero-banner-slider-root icon-button-black800">
         {dataList.length > 0 && (
            <Slider
               data-testid={HERO_BANNER_TEST_ID.slider}
               ref={sliderRef}
               autoPlay={autoPlay}
               autoPlayDelay={autoPlayDelay}
               breakpoints={{
                  sm: {
                     gutter: 0,
                     slidesToShow: 1,
                     slidesToScroll: 1,
                     seekGap: 0,
                  },
               }}
               data={dataList}
               distanceToSwipeSlide={50}
               loop
               onSlideChangeHandler={(data) => {
                  handleCurrentBannerChange(data.left);
               }}
               swipeGesture={isMobile}
               slideTransitionSpeed={slideTransitionSpeed}
            />
         )}
         {sliderData.widgetContainers.length > 1 && (
            <GridRow className="hero-banner-slider-controls-root">
               <GridColumn className="hero-banner-slider-controls">
                  <IconButton
                     data-testid={HERO_BANNER_TEST_ID.prevSlideButton}
                     size="xs"
                     ariaLabel='Previous Slide'
                     id="previousSlide"
                     type={
                        sliderData.widgetContainers[currentBanner]?.dataAttributes
                           ?.previousSlideButtonType || 'primary'
                     }
                     isDisabled={currentBanner === 0}
                     icon={LeftArrowIcon}
                     onClick={handleBannerPrev}
                  />
                  <Typography
                     data-testid={HERO_BANNER_TEST_ID.currentBannerDiv}
                     type="body-single-line-xs"
                     style={{
                        color:
                           sliderData.widgetContainers[currentBanner]?.dataAttributes
                              ?.subheadingColor || colors.black900,
                     }}
                  >
                     {currentBanner + 1}/{sliderData.widgetContainers.length}
                  </Typography>
                  <IconButton
                     data-testid={HERO_BANNER_TEST_ID.nextSlideButton}
                     size="xs"
                     id="nextSlide"
                     ariaLabel='Next Slide'
                     type={
                        sliderData.widgetContainers[currentBanner]?.dataAttributes
                           ?.nextSlideButtonType || 'primary'
                     }
                     icon={RightArrowIcon}
                     onClick={handleBannerNext}
                  />
               </GridColumn>
            </GridRow>
         )}
      </div>
   );
};

export default HeroBanner;
